<template>
  <div class="main-wrapper">
    <div class="main-wrapper-header">
      <div class="header-left">
        <el-breadcrumb separator=">" style="margin-left: 4px" >
          <el-breadcrumb-item>训练中心</el-breadcrumb-item>
          <el-breadcrumb-item>考试训练</el-breadcrumb-item>
          <el-breadcrumb-item>{{$route.query.trainName}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="header-right">
        <el-button @click="toBack" type="primary">返回</el-button>
      </div>
    </div>
    <el-table class="customTable" :data="trainList" style="width: 100%; margin-top: 20px; flex: 1;" height="1%" size="medium"
              :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
              :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
      <el-table-column prop="number" label="训练次数" align="left">
        <template slot-scope="scope">
          <span>第{{toChinesNum(scope.row.number)}}次</span>
        </template>
      </el-table-column>
      <el-table-column prop="student_total_score" label="分值" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.student_total_score}}分</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link @click="toDetail(scope.row.student_theory_train_log_id,scope.row.number)" type="success" :underline="false">查看详情</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pages-center" style="margin: 20px 0"
                   :current-page="listPages.currentPageNum"
                   :page-size="listPages.eachPageNum"
                   layout="prev, pager, next, jumper"
                   :total="listPages.total"
                   @current-change="pageCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import {trainAnswerDetailListStudent} from '@/utils/apis'
export default {
  name: "LookAnswerDetail",
  data(){
    return {
      trainList:[],
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    getList(){
      let params = {
        theory_test_paper_id:this.$route.query.id,
        course_id:this.$route.query.course_id,
        chapter_id:this.$route.query.chapter_id,
        lesson_id:this.$route.query.lesson_id,
        paging:1,
        page:this.listPages.currentPageNum,
        pageSize:this.listPages.eachPageNum
      }
      trainAnswerDetailListStudent(params).then((res)=>{
        this.trainList = res.data.list;
        this.listPages.total = res.data.total;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    toBack(){
      this.$router.go(-1)
    },
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val
      this.getList()
    },
    toDetail(logId,number){
      let numStr = this.toChinesNum(number);
      this.$router.push({
        path:'/student/trainCenter/lookAnswerDetail/paperDetail',
        query:{
          logId,
          numStr,
          trainName:this.$route.query.trainName
        }
      })
    },
    // 将数字转为大写
    toChinesNum(num){
      let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"
      let unit = ["", "十", "百", "千", "万"];
      num = parseInt(num);
      let getWan = (temp) => {
        let strArr = temp.toString().split("").reverse();
        let newNum = "";
        for (let i = 0; i < strArr.length; i++) {
          newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
        }
        return newNum;
      }
      let overWan = Math.floor(num / 10000);
      let noWan = num % 10000;
      if (noWan.toString().length < 4) noWan = "0" + noWan;
      return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-breadcrumb{
  .el-breadcrumb__item{
    .el-breadcrumb__inner{
      color: #999999;
    }
    &:last-of-type{
      .el-breadcrumb__inner{
        color: #333;
      }
    }
  }
  .el-breadcrumb__separator{
    color: #999;
    font-weight: 500;
  }
}
.main-wrapper{
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  .main-wrapper-header{
    padding: 0px 0 14px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #F1F5FF;
    .header-left{
      display: flex;
      align-items: center;
      span{
        line-height: 1;
      }
    }
  }
}
</style>